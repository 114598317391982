import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FinalizePrescriptionForm from '../../../../itrust_common/components/authorised/examFormPartial/FinalizePrescriptionForm';
import MedicalRxView from './MedicalRxView';
import SecureFaxForm from '../../secureFax/SecureFaxForm';
import GlassView from '../overview/refractionContactLens/GlassView'
import { ExportButton } from '../../../../itrust_common/components/others';

class ExamFinalizePrescriptionForm extends Component {

  componentWillUnmount(){
    if(this.props.dirty){
      this.props.handleSubmit();
    }
  } 

  render(){
    const { handleSubmit, template, patientId, onSecurFax, emailPrescriptionReportSend, handlePrintPrescription, exam, exam: { objective_attributes, subjective_attributes, cycloplegic_attributes, binocular_balance_attributes }, medicalRxReportSettings } = this.props;
    return (
      <div>
        <div className="d-flex flex-row justify-content-between align-items-center mx-auto mb-7 exam-form-header-btn">
          <div className="w-25">
            <SecureFaxForm onSubmit={onSecurFax}/>
          </div>
          <div>
            <button type="button" className="btn btn-outline-primary mr-lg-7 mr-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" onClick={()=> emailPrescriptionReportSend()}>Send Email</button>
            <button type="button" className="btn btn-outline-primary mr-lg-7 mr-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" onClick={handlePrintPrescription}>Print</button>
            <ExportButton label="Download" className="btn btn-outline-primary mr-lg-7 mr-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" path={`/v1/examinations/${exam.id}/download_prescription_report`} params={{['Store-Id']: localStorage.getItem('StoreID')}} onClickHook={()=> this.props.handleSubmit()}/>
            {/* <a target="_blank" download className="btn btn-outline-primary mr-lg-7 mr-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" href={buildDownloadUrl(`/v1/examinations/${exam.id}/download_prescription_report`, {['Store-Id']: localStorage.getItem('StoreID')})}>Download</a> */}
            <a className="collapsed btn btn-primary mx-md-4 my-md-2 mx-lg-0 my-lg-0" role="button" data-toggle="collapse" data-parent="#refraction-accordion" href="#refraction-collapseOne" aria-expanded="true" aria-controls="refraction-collapseOne">
              View Refraction
            </a>
          </div>
        </div>
        <div className="panel-group mb-7" id="refraction-accordion">
          <div className="panel panel-default">
            <div id="refraction-collapseOne" className={`panel-collapse collapse`}>
              <div className="panel-body bg-white">
                <div className="row px-5">
                  <div className="col-12">
                    <div className="border-bottom">
                      <h6 className="pb-3 pt-6">{template.refraction_contact_lens_exam.refraction.custom_label}</h6>
                    </div>
                  </div>
                  {objective_attributes && objective_attributes.is_marked_complete &&
                    <div className="col-6 py-4">
                      <h6 className="py-4">{template.refraction_contact_lens_exam.refraction.fields.objective.custom_label}</h6>
                      <GlassView fieldsValues={objective_attributes} template={template.refraction_contact_lens_exam.refraction.fields.objective}/>
                    </div>
                  }
                  {subjective_attributes && subjective_attributes.is_marked_complete &&
                    <div className="col-6 py-4">
                      <h6 className="py-4">{template.refraction_contact_lens_exam.refraction.fields.subjective.custom_label}</h6>
                      <GlassView fieldsValues={subjective_attributes} template={template.refraction_contact_lens_exam.refraction.fields.subjective}/>
                    </div>
                  }
                  {cycloplegic_attributes && cycloplegic_attributes.is_marked_complete &&
                    <div className="col-6 py-4">
                      <h6 className="py-4">{template.refraction_contact_lens_exam.refraction.fields.cycloplegic.custom_label}</h6>
                      <GlassView fieldsValues={cycloplegic_attributes} template={template.refraction_contact_lens_exam.refraction.fields.cycloplegic}/>
                    </div>
                  }
                  {binocular_balance_attributes && binocular_balance_attributes.is_marked_complete &&
                    <div className="col-6 py-4">
                      <h6 className="py-4">{template.refraction_contact_lens_exam.refraction.fields.binocular_balance.custom_label}</h6>
                      <GlassView fieldsValues={binocular_balance_attributes} template={template.refraction_contact_lens_exam.refraction.fields.binocular_balance}/>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div id="divToPrint">
            <FinalizePrescriptionForm pprops={this.props} template={template} isTemplate={false}/>
          </div>
          <div className="row finalize-prescription-form">
            <MedicalRxView patientId={patientId} pprops={{ ...this.props, patientReport: medicalRxReportSettings }} />
          </div>
        </form>
      </div>
    )
  }
}

ExamFinalizePrescriptionForm = reduxForm({
  form: 'examFinalizePrescriptionForm',
  enableReinitialize: true,
})(ExamFinalizePrescriptionForm)

// const selector = formValueSelector('examFinalizePrescriptionForm') 
ExamFinalizePrescriptionForm = connect(state => {
  const { setting: {patientReport: {patient_reports}, recommendation: {recommendations}, contactLensCleaningSolution: {contact_lens_cleaning_solutions}}, exam: { exam } }= state
  return {
    patientId: exam.patient_id,
    lensesAttributes: exam.finalized_lenses_attributes,
    recommendations: recommendations,
    // patientReport: patient_reports[0],
    medicalRxReportSettings: patient_reports?.find(item => item.category == "medical_rx"),
    contactLensCleaningSolutions: contact_lens_cleaning_solutions,
    initialValues: {
      id: exam.id,
      finalized_glasses_attributes: exam.finalized_glasses_attributes,
      finalized_lenses_attributes: exam.finalized_lenses_attributes,
    }
  }
})(ExamFinalizePrescriptionForm)

export default ExamFinalizePrescriptionForm;
